import React, { Component } from "react";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../axios/Axios";
import Alert from "../UI/Alert/Alert";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import Axios from "../../axios/Axios";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { userloginDetails } from "../Utils/AdminAuth";
class EditEmployee extends Component {
  constructor() {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType = lStorage.user.isAdmin;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      emp_type: empType,
      successMessage: false,
      selectEmpDetails: "",
      errorMessage: "",
      bgColor: "alert alert-success alert-dismissible",
      emp_id: null,
      emp_token: "",
      name: "",
      mobile: "",
      email: "",
      password: "",
      cnfmPassword: "",
      employeeType: "",
      checkBox: {
        all: null,
        sms: null,
        voice: null,
        email: null,
        whatsapp: null,
        recharge: null,
        notification: null,
        rich_media: null,
        fallback: null,
      },
      eyeIconToggle: false,
      passError: 0,
      updateEmployeeList: true,
      rcsPrice: false,
      _id: "",
      rcscountryCode: "",
      botid: "",
      basicrcscost: "",
      a2p_single_rcs_cost: "",
      a2p_conversation_cost: "",
      p2a_conversation_cost: "",
      margin: "",
      status: 1,
      frozen: 0,
      userBotId: [],
      rcsPriceList: [],
      selectedUserType: "",
      domain: "",
    };
    this.eyeToggle = this.eyeToggle.bind(this);
    this.handleCnfmPass = this.handleCnfmPass.bind(this);
    this.handlePass = this.handlePass.bind(this);
    this.handleCheckboxAll = this.handleCheckboxAll.bind(this);
    this.HandleEmployeeType = this.HandleEmployeeType.bind(this);
  }

  componentDidMount() {
    this.getRcsData();
    this.getBotid();
    this.setState({
      seletedUserId: this.props.match.params.id,
      selectedUserType: this.props.match.params.userType,
    });
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({
            selectEmpDetails: result.data[0],
            name: result.data[0].fullname,
            email: result.data[0].email,
            mobile: result.data[0].mobile,
            emp_token: result.data[0].token,
            emp_id: result.data[0].id,
            employeeType: result.data[0].user_type,
            domain:result.data[0].domain_name,
          });

          this.setState((prevState) => ({
            checkBox: {
              ...prevState.checkBox,

              ["sms"]: result.data[0].sms_menu,
              ["voice"]: result.data[0].voice_menu,
              ["email"]: result.data[0].email_menu,
              ["whatsapp"]: result.data[0].whatsapp_menu,
              ["recharge"]: result.data[0].rechrg_menu,
              ["notification"]: result.data[0].notify_menu,
              ["rich_media"]: result.data[0].rich_menu,
              ["fallback"]: result.data[0].fb_menu,
            },
          }));
          if (
            result.data[0].sms_menu === 1 &&
            result.data[0].voice_menu === 1 &&
            result.data[0].email_menu === 1 &&
            result.data[0].whatsapp_menu === 1 &&
            result.data[0].rechrg_menu === 1 &&
            result.data[0].rechrg_menu === 1 &&
            result.data[0].notify_menu === 1 &&
            result.data[0].rich_menu === 1 &&
            result.data[0].fb_menu === 1
          ) {
            this.setState((prevState) => ({
              checkBox: {
                ...prevState.checkBox,

                ["all"]: 1,
              },
            }));
          }
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.successMessage !== this.state.successMessage) {
      if (this.state.successMessage === true) {
        this.interval = setInterval(() => {
          this.setState({
            successMessage: false,
          });
        }, 5000);
      }
    }
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    let value;
    if (checked === true) {
      value = 1;
    } else {
      value = 0;
    }
    this.setState((prevState) => ({
      checkBox: {
        ...prevState.checkBox,
        [name]: value,
      },
    }));
  };

  eyeToggle(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      eyeIconToggle: !prevState.eyeIconToggle,
    }));
  }

  handlePass(e) {
    this.setState({
      password: e.target.value,
    });
    if (e.target.value === "" && this.state.cnfmPassword === "")
      this.setState({
        passError: 0,
      });
  }

  handleCnfmPass(e) {
    if (e.target.value === "" && this.state.password === "")
      this.setState({
        passError: 0,
      });

    if (this.state.password === "") {
      return;
    } else {
      this.setState({
        passError: 1,
      });
    }
    const pass = this.state.password;
    const cnfmPass = e.target.value;
    this.setState({
      cnfmPassword: e.target.value,
    });
    if (pass === cnfmPass) {
      this.setState({
        passError: 0,
      });
    } else {
      this.setState({
        passError: 1,
      });
    }
  }
  handleCheckboxAll(e) {
    const { checked } = e.target;
    let value;
    if (checked === true) {
      value = 1;
    } else {
      value = 0;
    }
    this.setState((prevState) => ({
      checkBox: {
        ...prevState.checkBox,
        all: value,
        sms: value,
        voice: value,
        email: value,
        whatsapp: value,
        recharge: value,
        notification: value,
        rich_media: value,
        fallback: value,
      },
    }));
  }

  HandleEmployeeType(e) {
    this.setState({
      employeeType: e.target.value,
    });
  }

  updateEmployedd = async () => {
    if (!this.state.name) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your name",
      });

      return;
    }
    if (!(this.state.cnfmPassword === this.state.password)) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "The passwords you entered don't match",
      });

      return;
    }

    const updateData = {
      id: this.state.emp_id,
      user_id: this.state.user_id,
      token: this.state.token,
      employee_type: this.state.employeeType,
      email: this.state.email,
      fullname: this.state.name,
      password: this.state.password,
      domain_name:this.state.domain,
      sms_menu: this.state.checkBox.sms,
      voice_menu: this.state.checkBox.voice,
      email_menu: this.state.checkBox.email,
      whatsapp_menu: this.state.checkBox.whatsapp,
      rechrg_menu: this.state.checkBox.recharge,
      notify_menu: this.state.checkBox.notification,
      rich_menu: this.state.checkBox.rich_media,
      fb_menu: this.state.checkBox.fallback,
      method: "update_emp",
    };
    // const data= await axios.post(`${server}/employee_user_list.php`, updateData)
    const { data } = await api.post("/api/employee_user_list.php", updateData);

    if (data.success === true) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-success alert-dismissible",
        errorMessage: data.message,
      });
    }
    if (data.success === false) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: data.message,
      });
    }
  };

  getBotid = async () => {
    const senderParamData = {
      user_id: this.state.user_id,
      token: this.state.token,
      method: "userwise_bot",
      client_id: this.props.match.params.id,
    };
    try {
      let { data } = await Axios.post(
        `${NODE_HOST_URL}/admin/rcs`,
        senderParamData
      );
      if (data.success) {
        this.setState({
          userBotId: data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  addRcsPrice = async () => {
    const paramData = {
      user_id: this.state.user_id,
      retr_user_id: this.props.match.params.id,
      token: this.state.token,
      method: "add_single_country_pricercs",
      country_code: this.state.rcscountryCode,
      bot_id: this.state.botid || "12345",
      margin: parseFloat(this.state.margin),
      parent_type: this.state.emp_type,
      client_type: this.props.match.params.userType,
      is_frozen: this.state.frozen,
      is_active: this.state.status,
    };
    try {
      let { data } = await Axios.post(
        `${NODE_HOST_URL}/rcs_setting`,
        paramData
      );
      if (data) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        setTimeout(() => {
          this.setState({ successMessage: false });
        }, 4000);
        this.getRcsData();
        this.setState({
          rcscountryCode: "",
          botid: "",
          margin: "",
          _id: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getRcsData = async () => {
    const retrieveParam = {
      user_id: this.state.user_id,
      userId: this.props.match.params.id,
      token: this.state.token,
      method: "retrieve_price",
    };
    try {
      let { data } = await Axios.post(
        `${NODE_HOST_URL}/admin/rcs_setting`,
        retrieveParam
      );
      if (data.success) {
        this.setState({ rcsPriceList: data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getRcsPrice = (
    id,
    countrycode,
    botid,
    rcscost,
    a2pcost,
    a2pconvcost,
    p2acost
  ) => {
    this.setState({
      _id: id,
      rcscountryCode: countrycode,
      botid: botid,
      basicrcscost: rcscost,
      a2p_single_rcs_cost: a2pcost,
      a2p_conversation_cost: a2pconvcost,
      p2a_conversation_cost: p2acost,
    });
  };

  updateRcsPrice = async () => {
    const paramupdateData = {
      user_id: this.state.user_id,
      token: this.state.token,
      retr_user_id: this.props.match.params.id,
      bot_id: this.state.botid || "12345",
      country_code: this.state.rcscountryCode,
      basic_sms_cost: parseFloat(this.state.basicrcscost),
      p2a_conv_cost: parseFloat(this.state.p2a_conversation_cost),
      a2p_conv_cost: parseFloat(this.state.a2p_conversation_cost),
      a2p_single_sms_cost: parseFloat(this.state.a2p_single_rcs_cost),
      method: "update_rcsprice",
      parent_type: this.state.emp_type,
      client_type: this.props.match.params.userType,
    };
    try {
      let { data } = await Axios.post(
        `${NODE_HOST_URL}/rcs_setting`,
        paramupdateData
      );
      if (data.success) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        setTimeout(() => {
          this.setState({ successMessage: false });
        }, 4000);
        this.getRcsData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // updateRcsPriceNew =async () =>{
  //   const paramupdateData = {
  //     user_id: this.state.user_id,
  //     token: this.state.token,
  //     retr_user_id: this.props.match.params.id,
  //     bot_id: "4467895",
  //     country_code: this.state.rcscountryCode,
  //     basic_sms_cost: parseFloat(this.state.basicrcscost),
  //     p2a_conv_cost: parseFloat(this.state.p2a_conversation_cost),
  //     a2p_conv_cost: parseFloat(this.state.a2p_conversation_cost),
  //     a2p_single_sms_cost: parseFloat(this.state.a2p_single_rcs_cost),
  //     method: "update_rcsprice",
  //     parent_type: this.state.emp_type,
  //     client_type: this.props.match.params.userType,
  //     sms_cost: 10,
  //     voice_cost: 9
  //   }
  //   try {
  //     let {data} = await Axios.post(`${NODE_HOST_URL}/rcs_setting`,paramupdateData);
  //     if(data.success){
  //       this.setState({
  //         successMessage: true,
  //         bgColor: "alert alert-success alert-dismissible",
  //         errorMessage: data.message,
  //       });
  //       setTimeout(() => {
  //         this.setState({ successMessage: false })
  //       }, 4000)
  //       this.getRcsData();
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  render() {
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              {this.state.successMessage && (
                <Alert type={this.state.bgColor}>
                  {this.state.errorMessage}
                </Alert>
              )}
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> Edit </strong> Employee{" "}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link
                              to={
                                "/admin/edit-employee/" +
                                this.state.seletedUserId +
                                `/` +
                                this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item active">
                                Update Details{" "}
                              </li>{" "}
                            </Link>
                            <Link
                              to={
                                "/admin/reseller-transaction-details/" +
                                this.state.seletedUserId +
                                `/` +
                                this.state.selectedUserType
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item">
                                {" "}
                                Transaction Details{" "}
                              </li>
                            </Link>

                            <Link
                              to={
                                "/admin/reseller-price-details/" +
                                this.state.seletedUserId +
                                `/` +
                                this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item"> Add Price </li>
                            </Link>
                            {this.state.emp_type === "admin" && (
                              <Link
                                to={
                                  "/admin/reseller-add-price/" +
                                  this.state.seletedUserId +
                                  `/` +
                                  this.state.selectedUserType
                                }
                              >
                                {" "}
                                <li className="breadcrumb-item">
                                  {" "}
                                  Add Credit{" "}
                                </li>
                              </Link>
                            )}
                            <Link
                              to={
                                "/admin/reseller-billing-mode/" +
                                this.state.seletedUserId +
                                `/` +
                                this.state.selectedUserType
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item">
                                {" "}
                                Billing Mode{" "}
                              </li>
                            </Link>
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>
                  <div className="card-body">
                    {this.state.updateEmployeeList && (
                      <div className="row">
                        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                          <div className=" flex-fill">
                            <form>
                              <div className="row">
                                <div className="mb-3 col-md-6">
                                  <label
                                    className="form-label"
                                    htmlFor="inputFirstName"
                                  >
                                    Employee type
                                  </label>
                                  <select
                                    onChange={this.HandleEmployeeType}
                                    className="form-control cursor-pointer"
                                  >
                                    <option value="">Select Employee</option>
                                    <option
                                      selected={
                                        this.state.employeeType === "subadmin"
                                          ? true
                                          : false
                                      }
                                      value={"subadmin"}
                                    >
                                      Sub Admin
                                    </option>
                                    <option
                                      selected={
                                        this.state.employeeType === "emp"
                                          ? true
                                          : false
                                      }
                                      value={"emp"}
                                    >
                                      Employee
                                    </option>
                                    <option
                                      selected={
                                        this.state.employeeType === "reseller"
                                          ? true
                                          : false
                                      }
                                      value={"reseller"}
                                    >
                                      Reseller
                                    </option>
                                  </select>
                                </div>

                                <div className="mb-3 col-md-6">
                                  <label
                                    className="form-label"
                                    htmlFor="inputFirstName"
                                  >
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    placeholder="Enter Full Name"
                                    required
                                  />
                                </div>
                                <div
                                  className="mb-3 col-md-6 cursor-no-drop
"
                                >
                                  <label
                                    className="form-label"
                                    htmlFor="inputEmail4"
                                  >
                                    Email
                                  </label>
                                  <input
                                    readOnly
                                    type="email"
                                    defaultValue={this.state.email}
                                    className="form-control cursor-no-drop
                                  "
                                    placeholder="Email"
                                    disabled
                                  />
                                </div>

                                <div className="mb-3 col-md-6">
                                  <label
                                    className="form-label"
                                    htmlFor="inputFirstName"
                                  >
                                    Enter Mobile Number
                                  </label>
                                  <input
                                    readOnly
                                    type="text"
                                    defaultValue={this.state.mobile}
                                    onChange={(event) =>
                                      this.setState({
                                        mobile: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    placeholder="Enter 10 Digit Mobile Number"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="mb-3 col-md-6">
                                  <label
                                    className="form-label"
                                    htmlFor="inputFirstName"
                                  >
                                    Password
                                  </label>
                                  <div className="d-flex flex-row">
                                    <input
                                      type={
                                        this.state.eyeIconToggle === true
                                          ? "text"
                                          : "password"
                                      }
                                      defaultValue={this.state.password}
                                      onChange={this.handlePass}
                                      className="form-control"
                                      placeholder="Enter new password"
                                      required
                                    />

                                    <button
                                      className="border-0 "
                                      onClick={this.eyeToggle}
                                    >
                                      {this.state.eyeIconToggle === true ? (
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                      ) : (
                                        <FontAwesomeIcon icon={faEye} />
                                      )}
                                    </button>
                                  </div>
                                </div>

                                <div className="mb-3 col-md-6">
                                  <label
                                    className="form-label"
                                    htmlFor="inputFirstName"
                                  >
                                    Confrim Password
                                  </label>
                                  <div className="d-flex flex-row">
                                    <input
                                      type={
                                        this.state.eyeIconToggle === true
                                          ? "text"
                                          : "password"
                                      }
                                      defaultValue={this.state.cnfmPassword}
                                      onChange={this.handleCnfmPass}
                                      className="form-control"
                                      placeholder="Enter new password"
                                      required
                                    />
                                    <button
                                      className="border-0 "
                                      onClick={this.eyeToggle}
                                    >
                                      {this.state.eyeIconToggle === true ? (
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                      ) : (
                                        <FontAwesomeIcon icon={faEye} />
                                      )}
                                    </button>
                                  </div>

                                  {this.state.passError === 1 && (
                                    <p
                                      className="text-danger"
                                      style={{ fontSize: "10px" }}
                                    >
                                      *The passwords you entered don't match
                                    </p>
                                  )}
                                </div>
                              </div>
                              {this.state.employeeType==="reseller"&&<div className="row">
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Domain <small>(*Note:- Enter domain without https:// or http://)</small></label>

                                  <input
                                    type={"text"}
                                    value={this.state.domain}
                                    onChange={(e)=>this.setState({domain:e.target.value})}
                                    className="form-control"
                                    placeholder="Enter your domain"
                                 
                                  />
                                </div>
                              </div>}
                              <div className="row ml-2">
                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    checked={
                                      this.state.checkBox.all === 1
                                        ? true
                                        : false
                                    }
                                    name="all"
                                    value={this.state.checkBox.all}
                                    onChange={this.handleCheckboxAll}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    All
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    checked={
                                      this.state.checkBox.sms === 1
                                        ? true
                                        : false
                                    }
                                    name="sms"
                                    value={this.state.checkBox.sms}
                                    onChange={this.handleCheckbox}
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    SMS
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="voice"
                                    checked={
                                      this.state.checkBox.voice === 1
                                        ? true
                                        : false
                                    }
                                    value={this.state.checkBox.voice}
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Voice
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    value={this.state.checkBox.email}
                                    checked={
                                      this.state.checkBox.email === 1
                                        ? true
                                        : false
                                    }
                                    name="email"
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Email
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="whatsapp"
                                    value={this.state.checkBox.whatsapp}
                                    checked={
                                      this.state.checkBox.whatsapp === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Whatsapp
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="recharge"
                                    value={this.state.checkBox.recharge}
                                    checked={
                                      this.state.checkBox.recharge === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Recharge
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="notification"
                                    value={this.state.checkBox.notification}
                                    checked={
                                      this.state.checkBox.notification === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Notification
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="rich_media"
                                    value={this.state.checkBox.rich_media}
                                    checked={
                                      this.state.checkBox.rich_media === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Rich Media
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="fallback"
                                    value={this.state.checkBox.fallback}
                                    checked={
                                      this.state.checkBox.fallback === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Fallback
                                  </label>
                                </div>
                              </div>

                              <div className="">
                                <input
                                  type="button"
                                  onClick={() => this.updateEmployedd()}
                                  value="Update Employee Details"
                                  className="btn float-right  btn-primary"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.rcsPrice && (
                      <div className="card-body">
                        <div className="whatsapp-options">
                          <div className="row">
                            <div className="col-6"></div>
                            <div className="col-6">
                              <label className="switch float-right mb-3">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    this.state.isChecked === true
                                      ? "checked"
                                      : null
                                  }
                                  onChange={this.buttonHnadlar}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span className="float-right mr-2">
                                <b>All Country Price</b>
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.state.isChecked ? (
                          <>
                            <div className="row">
                              <div className="col-4">
                                <button
                                  onClick={() =>
                                    this.setState({ showModal: true })
                                  }
                                  className="btn btn-secondary mt-3"
                                  style={{
                                    fontSize: "12px",
                                    padding: "10px 10px 10px 10px",
                                  }}
                                >
                                  <span className="mr-2">
                                    <FontAwesomeIcon icon={faPlus} />
                                  </span>
                                  Add Price
                                </button>
                              </div>
                            </div>
                            {/* <div className="input-group mb-2 mt-4 mr-sm-2">
                                                          <div className="input-group-prepend">
                                                            <div className="input-group-text">AMD CSV File</div>
                                                          </div>
                                                          <input
                                                            type="file"
                                                            name="file"
                                                            accept=".csv"
                                                            onChange={this.handleFileChange}
                                                            className="form-control"
                                                          />
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-6">
                                                          <button type="button" onClick={()=>this.UploadCsv()} className="btn btn-info">Upload</button>
                                                          </div>
                                                        </div> */}
                          </>
                        ) : (
                          <div className="row">
                            <div className="col-md-4">
                              <label className="form-label">County Code</label>
                              <input
                                type="number"
                                defaultValue={this.state.rcscountryCode}
                                onChange={(event) =>
                                  this.setState({
                                    rcscountryCode: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                placeholder="91"
                                className="form-control"
                              />
                            </div>

                            <div className="col-md-4">
                              <label className="form-label">Bot Id</label>
                              <select
                                value={this.state.botid}
                                className="form-control"
                                onChange={(e) =>
                                  this.setState({
                                    botid: e.target.value,
                                    successMessage: false,
                                  })
                                }
                              >
                                <option value={""}>select Bot ID</option>
                                {this.state.userBotId?.map((x) => (
                                  <option value={x.bot_id}>{x.bot_id}</option>
                                ))}
                              </select>
                              {/* <input
                                                            type="text"
                                                            defaultValue={this.state.botid}
                                                            onChange={(event) =>
                                                              this.setState({ botid: event.target.value, successMessage: false })
                                                            }
                                                            placeholder="Rs."
                                                            className="form-control"
                                                          /> */}
                            </div>

                            {!this.state._id && (
                              <div className="col-md-4">
                                <label className="form-label">Margin</label>
                                <input
                                  type="text"
                                  defaultValue={this.state.margin}
                                  onChange={(event) =>
                                    this.setState({
                                      margin: event.target.value,
                                      successMessage: false,
                                    })
                                  }
                                  placeholder="Rs."
                                  className="form-control"
                                />
                              </div>
                            )}

                            {this.state._id ? (
                              <>
                                <div className="col-md-4">
                                  <label className="form-label">
                                    Basic Rcs Cost
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.state.basicrcscost}
                                    onChange={(event) =>
                                      this.setState({
                                        basicrcscost: event.target.value,
                                        successMessage: false,
                                      })
                                    }
                                    placeholder="Rs."
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label className="form-label">
                                    A2P Single Rcs Cost
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={
                                      this.state.a2p_single_rcs_cost
                                    }
                                    onChange={(event) =>
                                      this.setState({
                                        a2p_single_rcs_cost: event.target.value,
                                        successMessage: false,
                                      })
                                    }
                                    placeholder="Rs."
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label className="form-label">
                                    A2P Conversation Cost
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={
                                      this.state.a2p_conversation_cost
                                    }
                                    onChange={(event) =>
                                      this.setState({
                                        a2p_conversation_cost:
                                          event.target.value,
                                        successMessage: false,
                                      })
                                    }
                                    placeholder="Rs."
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label className="form-label">
                                    P2A Conversation Cost
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={
                                      this.state.p2a_conversation_cost
                                    }
                                    onChange={(event) =>
                                      this.setState({
                                        p2a_conversation_cost:
                                          event.target.value,
                                        successMessage: false,
                                      })
                                    }
                                    placeholder="Rs."
                                    className="form-control"
                                  />
                                </div>
                              </>
                            ) : null}
                            {this.state._id && (
                              <div className="col-md-4">
                                <label className="form-label">Status</label>
                                <select
                                  className="form-control"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({
                                      status: e.target.value,
                                      successMessage: false,
                                    })
                                  }
                                >
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                              </div>
                            )}

                            {this.state._id && (
                              <div className="col-md-4">
                                <label className="form-label">Frozen</label>
                                <select
                                  className="form-control"
                                  value={this.state.frozen}
                                  onChange={(e) =>
                                    this.setState({
                                      frozen: e.target.value,
                                      successMessage: false,
                                    })
                                  }
                                >
                                  <option value="1">Frozen</option>
                                  <option value="0">non-Forzen</option>
                                </select>
                              </div>
                            )}

                            <div className="col-md-12 text-right">
                              {this.state._id ? (
                                <div
                                  style={{ marginTop: 40 }}
                                  className="input-group"
                                >
                                  <input
                                    type="button"
                                    onClick={() => this.updateRcsPrice()}
                                    value="Update"
                                    className="btn btn-sm btn-primary text-center"
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ marginTop: 40 }}
                                  className="input-group text-right"
                                >
                                  <input
                                    type="button"
                                    onClick={() => this.addRcsPrice()}
                                    value="Add"
                                    className="btn btn-sm btn-primary text-center"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {this.state.rcsPrice && (
                      <div className="row">
                        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                          <div className="card table-responsive flex-fill">
                            {this.state.rcsPriceList ? (
                              <table className="table table-hover my-0">
                                <thead>
                                  <tr>
                                    <th>Country Code</th>
                                    <th className="">Bot ID</th>
                                    <th className="">Rcs Cost</th>
                                    <th className="">A2P Sinagle Rcs Cost</th>
                                    <th className="">
                                      {" "}
                                      A2P Conversation Cost{" "}
                                    </th>
                                    <th className="">
                                      {" "}
                                      P2A Conversation Cost{" "}
                                    </th>
                                    <th className=""> Date/Time </th>
                                    {this.state.emp_type ===
                                    "reseller" ? null : (
                                      <>
                                        <th className=""> Edit </th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.rcsPriceList.map((item, i) => (
                                    <tr key={i}>
                                      <td>{item.country_code}</td>
                                      <td>{item.bot_id}</td>
                                      <td>{item.basic_sms_cost}</td>
                                      <td>{item.a2p_single_sms_cost}</td>
                                      <td>{item.a2p_conv_cost}</td>
                                      <td>{item.p2a_conv_cost}</td>
                                      <td>
                                        {dayjs(item.created_date).format(
                                          "DD/MM/YYYY h:mm A"
                                        )}
                                      </td>
                                      {this.state.emp_type ===
                                      "reseller" ? null : (
                                        <>
                                          <td>
                                            <span
                                              onClick={() =>
                                                this.getRcsPrice(
                                                  item._id,
                                                  item.country_code,
                                                  item.bot_id,
                                                  item.basic_sms_cost,
                                                  item.a2p_single_sms_cost,
                                                  item.a2p_conv_cost,
                                                  item.p2a_conv_cost
                                                )
                                              }
                                              className="badge bg-success"
                                            >
                                              <FontAwesomeIcon icon={faEdit} />{" "}
                                              Edit
                                            </span>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <div className="text-center">
                                <div className="card-body">
                                  <img
                                    alt="Empty Data"
                                    className="project-empty-img"
                                    src={EmptyImg}
                                  />
                                  <p>No Any User Price Found</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default EditEmployee;
